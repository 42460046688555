import React from 'react';
import styled from 'styled-components';
import { graphql, Link, StaticQuery } from 'gatsby';

import Logo from '../images/logo-white.svg';
import Copyright from './Copyright';
import { Container } from './ui';
import { brandColours, fontSize, minBreakpointQuery } from '../utilities';
import CallToActionStrip from './CallToActionStrip';

const Footer = styled.footer`
  color: ${brandColours.septenary};
  background: ${brandColours.tertiary};
`;

const Wrapper = styled.div`
  padding: 224px 0 30px;

  ${minBreakpointQuery.small`
    padding: 200px 0 60px;
  `}

  ${minBreakpointQuery.medium`
    padding: 220px 0 90px ;
  `}

  ${minBreakpointQuery.large`
    display: flex;
    justify-content: space-between;
    padding: 196px 0 190px;
  `}
`;

const Heading = styled.h3`
  margin-bottom: 20px;
  ${fontSize(20)};
  color: ${brandColours.primary};
  ${minBreakpointQuery.large`
    margin-bottom: 24px;
  `}
`;

const LogoWrap = styled.div`
  margin-top: 60px;
  text-align: center;
  ${minBreakpointQuery.large`
    margin-top: 0;
  `}
`;

const Blocks = styled.div`
  ${minBreakpointQuery.tsmall`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 20px;
  `}
  ${minBreakpointQuery.small`
    grid-template-columns: repeat(3, 1fr);
  `}
  ${minBreakpointQuery.large`
    gap: 0 120px;
  `}
`;

const Block = styled.div`
  padding: 18px 0;
  ${minBreakpointQuery.large`
    padding: 0;
  `}
`;

const List = styled.ul`
  li {
    margin-top: 10px;
  }
`;

const LinkOrExternal = ({ slug, linkType, linkUrl, children }) => {
  if (slug) {
    return <Link to={`/${slug}`}>{children}</Link>;
  }

  let alteredUrl;

  if (linkType === 2) {
    alteredUrl = `mailto:${linkUrl}`;
  } else if (linkType === 3) {
    alteredUrl = `tel:${linkUrl}`;
  }

  return (
    <a href={alteredUrl || linkUrl} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

const FooterComponent = ({
  data: {
    datoCmsFooter: { linksBlocks },
  },
}) => {
  return (
    <>
      <CallToActionStrip />
      <Footer>
        <Container>
          <Wrapper>
            {linksBlocks && (
              <Blocks>
                {linksBlocks.map(({ instance: { heading, id, links } }) => {
                  return (
                    <Block key={id}>
                      <Heading>{heading}</Heading>
                      {links && (
                        <List>
                          {links.map(({ id, text, page }) => {
                            return (
                              <li key={id}>
                                <LinkOrExternal {...page}>
                                  {text}
                                </LinkOrExternal>
                              </li>
                            );
                          })}
                        </List>
                      )}
                    </Block>
                  );
                })}
              </Blocks>
            )}
            <LogoWrap>
              <Link to={'/'}>
                <img src={Logo} alt="Logo" />
              </Link>
            </LogoWrap>
          </Wrapper>
        </Container>
        <Copyright />
      </Footer>
    </>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        datoCmsFooter {
          linksBlocks {
            instance {
              id
              heading
              links {
                id
                text
                page {
                  ... on DatoCmsHome {
                    slug
                  }
                  ... on DatoCmsPage {
                    slug
                  }
                  ... on DatoCmsCompetitionsArchive {
                    slug
                  }
                  ... on DatoCmsCompetitionSingle {
                    slug
                  }
                  ... on DatoCmsEventsArchive {
                    slug
                  }
                  ... on DatoCmsEventSingle {
                    slug
                  }
                  ... on DatoCmsNewsArchive {
                    slug
                  }
                  ... on DatoCmsNewsSingle {
                    slug
                  }
                  ... on DatoCmsExternalLink {
                    linkType
                    linkUrl
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <FooterComponent data={data} {...props} />}
  />
);
