import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  fontSize,
  fontWeights,
  zIndexLayers,
  standardTransition,
} from '../utilities';

const StyledNavigation = styled.div`
  ${minBreakpointQuery.mlarge`
    flex-grow: 1;
    max-width: 1400px;
  `}
`;

const StyledNavigationToggle = styled.button`
  ${maxBreakpointQuery.mlarge`
    position: relative;
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: ${brandColours.secondary};
    border: none;
    cursor: pointer;
  `}

  ${minBreakpointQuery.tiny`
    margin-right: 60px;
  `}

  ${minBreakpointQuery.small`
    margin-right: 70px;
  `}

  ${minBreakpointQuery.mlarge`
    display: none;
  `}

  span {
    ${maxBreakpointQuery.mlarge`
      display: block;
      height: 4px;
      width: 30px;
      margin: 8px 20px;
      background-color: ${standardColours.white};
    `}

    ${maxBreakpointQuery.small`
      height: 3px;
      width: 24px;
      margin: 6px 18px;
    `}
  }
`;

const StyledNavigationLinks = styled.nav`
  ${maxBreakpointQuery.mlarge`
    position: absolute;
    top: 70px;
    right: 0;
    left: 0;
    z-index: ${zIndexLayers.fourth};
    display: none;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: ${brandColours.secondary}
  `}

  ${maxBreakpointQuery.small`
    top: 60px;
    padding-top: 30px;
    padding-bottom: 30px;
  `}

  ${minBreakpointQuery.mlarge`
    height: 100%;
  `}

  &.js-nav-open {
    ${maxBreakpointQuery.mlarge`
      display: block;
    `}
  }
`;

const StyledNavigationList = styled.ul`
  ${minBreakpointQuery.mlarge`
    display: flex;
    justify-content: space-around;
    margin-right: 30px;
    margin-left: 30px;
    height: 100%;
  `}

  ${minBreakpointQuery.large`
    margin-right: 40px;
    margin-left: 40px;
  `}
`;

const StyledNavigationItem = styled.li`
  ${maxBreakpointQuery.mlarge`
    margin-top: 10px;
  `}

  ${maxBreakpointQuery.small`
    margin-top: 6px;
  `}

  ${minBreakpointQuery.mlarge`
    display: inline-block;
    height: 100%;
    margin-left: 10px;
  `}

  &:first-child {
    ${maxBreakpointQuery.mlarge`
      margin-top: 0;
    `}

    ${minBreakpointQuery.mlarge`
      margin-left: 0;
    `}
  }
`;

const StyledNavigationLink = styled(Link)`
  font-weight: ${fontWeights.bold};

  ${maxBreakpointQuery.mlarge`
    display: block;
    padding: 10px 20px;
    color: ${standardColours.white};
    ${fontSize(24)};
  `}

  ${maxBreakpointQuery.small`
    padding-top: 6px;
    padding-bottom: 6px;
  `}

  ${maxBreakpointQuery.small`
    ${fontSize(20)};
  `}

  ${minBreakpointQuery.mlarge`
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px;
    height: 100%;
    color: ${brandColours.secondary}
    ${fontSize(15)};
    text-align: center;
  `}

  ${minBreakpointQuery.large`
    ${fontSize(16)};
  `}

  &:after {
    ${minBreakpointQuery.mlarge`
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 4px;
      ${standardTransition('background-color')};
    `}
  }

  &:hover {
    &:after {
      ${minBreakpointQuery.mlarge`
        background-color: ${brandColours.secondary};
      `}
    }
  }

  &.current-page {
    &:after {
      ${minBreakpointQuery.mlarge`
        background-color: ${brandColours.primary};
      `}
    }
  }
`;

const Navigation = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      toggleNavigationClass: '',
    };
  }

  toggleNavigation = () => {
    this.setState({ active: !this.state.active }, () => {
      this.state.active
        ? this.setState({ toggleNavigationClass: 'js-nav-open' })
        : this.setState({ toggleNavigationClass: '' });
    });
  };

  render() {
    const {
      data: {
        datoCmsHeader: { navigation },
      },
    } = this.props;

    return (
      <StyledNavigation>
        <StyledNavigationToggle
          aria-label="Open / Close Navigation"
          className={`${this.state.toggleNavigationClass}`}
          onClick={() => this.toggleNavigation()}
        >
          <span />
          <span />
          <span />
        </StyledNavigationToggle>
        <StyledNavigationLinks
          className={`${this.state.toggleNavigationClass}`}
        >
          <StyledNavigationList>
            {navigation.map(({ id, text, page: { slug } }) => (
              <StyledNavigationItem key={id}>
                <StyledNavigationLink
                  to={`/${slug}`}
                  activeClassName="current-page"
                  partiallyActive={true}
                >
                  {text}
                </StyledNavigationLink>
              </StyledNavigationItem>
            ))}
          </StyledNavigationList>
        </StyledNavigationLinks>
      </StyledNavigation>
    );
  }
};

export default props => (
  <StaticQuery
    query={graphql`
      query NavigationQuery {
        datoCmsHeader {
          navigation {
            id
            text
            page {
              ... on DatoCmsHome {
                slug
              }
              ... on DatoCmsPage {
                slug
              }
              ... on DatoCmsCompetitionsArchive {
                slug
              }
              ... on DatoCmsCompetitionSingle {
                slug
              }
              ... on DatoCmsEventsArchive {
                slug
              }
              ... on DatoCmsEventSingle {
                slug
              }
              ... on DatoCmsNewsArchive {
                slug
              }
              ... on DatoCmsNewsSingle {
                slug
              }
            }
          }
        }
      }
    `}
    render={data => <Navigation data={data} {...props} />}
  />
);
