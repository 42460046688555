import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { standardColours, brandColours, fontWeights } from '../../utilities';

const StyledCardsLink = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: ${fontWeights.bold};
  white-space: nowrap;

  ${({ alt }) => {
    if (alt) {
      return `
        color: ${standardColours.white};
      `;
    }
  }}

  &:before {
    content: '';
    display: block;
    margin-right: 16px;
    height: 3px;
    width: 100%;
    background-color: ${brandColours.primary};
  }
`;

export const CardsLink = ({ slug, alt, children }) => {
  return (
    <StyledCardsLink to={slug} alt={alt}>
      {children}
    </StyledCardsLink>
  );
};
