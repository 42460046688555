import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { maxBreakpointQuery, minBreakpointQuery, fontSize } from '../utilities';
import Logo from '../images/logo.svg';
import Navigation from './Navigation';
import { Button } from './ui';

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  padding-right: 15px;

  ${minBreakpointQuery.small`
    padding-right: 20px;
  `}

  ${minBreakpointQuery.mlarge`
    padding-left: 20px;
  `}
`;

const StyledHeaderLogo = styled(Link)`
  display: flex;

  ${maxBreakpointQuery.mlarge`
    order: 2;
    margin: 10px 15px;
  `}

  ${maxBreakpointQuery.small`
    height: 40px;
  `}

  ${minBreakpointQuery.small`
    max-width: 150px;
  `}
  
  ${minBreakpointQuery.mlarge`
    margin-top: 15px;
    margin-bottom: 15px;
    max-width: 160px;
  `}
`;

const StyledHeaderButtonLink = styled(Button)`
  align-self: center;

  ${maxBreakpointQuery.mlarge`
    order: 3;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    min-width: auto;
    white-space: nowrap;
    ${fontSize(14)};
  `}

  ${minBreakpointQuery.small`
    padding: 14px;
    ${fontSize(15)};
  `}

  ${minBreakpointQuery.mlarge`
    margin-top: 15px;
    margin-bottom: 15px;
  `}
`;

const Header = ({
  data: {
    datoCmsHeader: {
      contactLinkPage: { slug },
    },
  },
}) => {
  return (
    <StyledHeader>
      <StyledHeaderLogo to="/">
        <img
          src={Logo}
          alt="Birkenhead Gymnastics Academy Logo"
          loading="lazy"
        />
      </StyledHeaderLogo>
      <Navigation />
      <StyledHeaderButtonLink to={`/${slug}`}>
        Get in touch
      </StyledHeaderButtonLink>
    </StyledHeader>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        datoCmsHeader {
          contactLinkPage {
            slug
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
);
