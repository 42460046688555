import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery } from '../../utilities';

const StyledGoogleMap = styled.div`
  position: relative;
  overflow: hidden;
  margin: 30px 0;
  padding-top: 75%;

  ${minBreakpointQuery.small`
    margin-top: 40px;
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.medium`
    margin-top: 50px;
    margin-bottom: 50px;
  `}
`;

const StyledGoogleMapIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 0;
`;

export const GoogleMap = ({ location }) => {
  const apiKey = 'AIzaSyDggaQnFwMMn5X_td_N_5b6PT4JWqZ4b9k';

  return (
    <StyledGoogleMap>
      <StyledGoogleMapIframe
        src={`https://www.google.com/maps/embed/v1/place?q=${location}&key=${apiKey}`}
      ></StyledGoogleMapIframe>
    </StyledGoogleMap>
  );
};
