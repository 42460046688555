import React from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  fluidFontSize,
  brandColours,
  minBreakpointQuery,
  fontWeights,
} from '../../utilities';

const Wrapper = styled.header`
  position: relative;
  margin-bottom: 30px;
  text-align: center;
  color: ${brandColours.secondary};

  ${minBreakpointQuery.mlarge`
    margin-bottom: 24px;
  `}
`;

const HeadingBackground = styled.span`
  display: block;
  font-weight: ${fontWeights.bold};
  ${fluidFontSize(
    '52px',
    '164px',
    breakpointSizes.tiny,
    breakpointSizes.large
  )};
  line-height: 0.8;
  letter-spacing: -2px;
  text-transform: uppercase;
  opacity: 0.05;
`;

const Heading = styled.h2`
  ${fluidFontSize('24px', '30px', breakpointSizes.tiny, breakpointSizes.large)};
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
`;

export const HeadingWithBackgroundText = ({
  heading,
  headingBackgroundText,
}) => {
  return (
    <Wrapper>
      <HeadingBackground>{headingBackgroundText}</HeadingBackground>
      <Heading>{heading}</Heading>
    </Wrapper>
  );
};
