import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Button, Container } from './ui';
import {
  standardColours,
  minBreakpointQuery,
  fluidFontSize,
  breakpointSizes,
  zIndexLayers,
} from '../utilities';

const Banner = styled.section`
  position: relative;
  text-align: center;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: ${standardColours.black};
    opacity: 0.4;
  }
`;

const Image = styled(Img)`
  min-height: 100%;
  ${minBreakpointQuery.mlarge`
  ${props =>
    !props.isSubBanner
      ? `
      min-height: 520px;
        `
      : ''};
  `}
  ${minBreakpointQuery.large`
    min-height: ${props => !props.isSubBanner && '680px'};
  `}
  ${minBreakpointQuery.xxlarge`
   ${props => !props.isSubBanner && `min-height: 780px;`}
  `}
`;

const getLowerFontSize = isSubBanner => (isSubBanner ? '25px' : '30px');
const getUpperFontSize = isSubBanner => (isSubBanner ? '35px' : '40px');

const Heading = styled.h1`
  ${fluidFontSize(
    `${getLowerFontSize(props => props.isSubBanner)}`,
    `${getUpperFontSize(props => props.isSubBanner)}`,
    breakpointSizes.tiny,
    breakpointSizes.large
  )}
  color: ${standardColours.white};
  ${minBreakpointQuery.mlarge`
    line-height: 1.15;
  `}
`;

const Date = styled.p`
  margin-top: 10px;
  color: ${standardColours.white};
`;

const ContentWrap = styled.div`
  position: relative;
  z-index: ${zIndexLayers.first};
  min-height: ${props => (props.isSubBanner ? '250px' : '470px')};

  ${props =>
    props.isSubBanner
      ? `
    display: flex;
    align-items: center;
    padding: 30px 10px;
  `
      : `
    padding: 140px 10px 65px;
  `}

  ${minBreakpointQuery.mlarge`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-height: auto;
    padding-top: ${props => (props.isSubBanner ? '30' : '180')}px;
    height: ${props => props.isSubBanner && '100%'};
  `}

  ${minBreakpointQuery.xxlarge`
    padding-top: ${props => !props.isSubBanner && '234px'};
  `}
`;

const ImageWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  ${minBreakpointQuery.mlarge`
    position: relative;
  `}
`;

const ButtonWrap = styled.div`
  margin-top: 16px;
  ${minBreakpointQuery.small`
    display: inline-block;
  `}
`;

const BannerComponent = ({
  data: {
    datoCmsBannerImage: { defaultSubpageBannerImage },
  },
  title,
  date,
  linkPage,
  linkText,
  image,
}) => {
  const isSubBanner = typeof linkPage === 'undefined';
  const bannerImage = image || defaultSubpageBannerImage;

  return (
    <Banner>
      <ImageWrap>
        <Image
          fluid={bannerImage.fluid}
          fadeIn={false}
          loading="eager"
          alt={bannerImage.alt}
          isSubBanner={isSubBanner}
        />
      </ImageWrap>
      <ContentWrap isSubBanner={isSubBanner}>
        <Container>
          <Heading isSubBanner={isSubBanner}>{title}</Heading>
          {date && (
            <Date>
              <time dateTime={date.attribute}>{date.text}</time>
            </Date>
          )}
          {linkPage && (
            <ButtonWrap>
              <Button to={`/${linkPage.slug}`}>{linkText}</Button>
            </ButtonWrap>
          )}
        </Container>
      </ContentWrap>
    </Banner>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query BannerComponentQuery {
        datoCmsBannerImage {
          defaultSubpageBannerImage {
            fluid(
              maxHeight: 380
              maxWidth: 1600
              imgixParams: {
                auto: "compress"
                fit: "crop"
                h: "380"
                w: "1600"
              }
            ) {
              ...GatsbyDatoCmsFluid
            }
            alt
          }
        }
      }
    `}
    render={data => <BannerComponent data={data} {...props} />}
  />
);
