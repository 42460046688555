import React from 'react';
import styled from 'styled-components';
import { breakpointSizes, fluidFontSize } from '../../utilities';

const StyledHeading = styled.h2`
  ${fluidFontSize('22px', '30px', breakpointSizes.tiny, breakpointSizes.large)};

  ${({ colour }) => {
    if (colour) {
      return `
        color: ${colour};
      `;
    }
  }}

  ${({ align }) => {
    if (align) {
      return `
        text-align: ${align};
      `;
    }
  }}
`;

export const Heading = ({ colour, align, children, ...props }) => {
  return (
    <StyledHeading colour={colour} align={align} {...props}>
      {children}
    </StyledHeading>
  );
};
