import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import GlobalStyle from './Global';
import Header from './Header';
import Footer from './Footer';

const Layout = ({
  data: {
    datoCmsSite: { faviconMetaTags },
  },
  children,
}) => {
  return (
    <>
      <GlobalStyle />
      <HelmetDatoCms favicon={faviconMetaTags}>
        <html lang="en-GB" />
      </HelmetDatoCms>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        datoCmsSite {
          faviconMetaTags {
            ...GatsbyDatoCmsFaviconMetaTags
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);
