import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Container, Button, Heading } from './ui';
import {
  fontSize,
  brandColours,
  standardColours,
  minBreakpointQuery,
  zIndexLayers,
  maxBreakpointQuery,
} from '../utilities';

const CallToActionStrip = styled.section`
  margin-bottom: -180px;
  ${minBreakpointQuery.small`
    margin-bottom: -120px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-bottom: -84px;
  `}
`;

const Background = styled.div`
  position: relative;
  padding: 72px 0;
  ${minBreakpointQuery.mlarge`
    padding-top: 100px;
    padding-bottom: 90px;
  `}
  ${minBreakpointQuery.xxlarge`
    padding-top: 135px;
    padding-bottom: 124px;
  `}
  &:after {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(90deg, #151515 0%, rgba(0, 0, 0, 0) 100%);
  }
`;

const ImageWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const Image = styled(Img)`
  height: 100%;
`;

const ContentWrap = styled.div`
  position: relative;
  z-index: ${zIndexLayers.first};
  ${maxBreakpointQuery.mlarge`
    text-align: center;
  `}
  ${minBreakpointQuery.mlarge`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

const Text = styled.p`
  margin-top: 12px;
  ${fontSize(16)};
  color: ${standardColours.white};

  ${minBreakpointQuery.smedium`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.mlarge`
    ${fontSize(20)};
    max-width: 300px;
    line-height: 30px;
  `}
`;

const ButtonWrap = styled.div`
  margin-top: 24px;
  display: block;
  ${minBreakpointQuery.mlarge`
    margin-top: 0;
  `}
`;

const CallToActionComponent = ({
  data: {
    datoCmsCallToActionStrip: {
      backgroundImage: { fluid, alt },
      heading,
      linkPage,
      linkText,
      text,
    },
  },
}) => {
  return (
    <CallToActionStrip>
      <Container className="large">
        <Background>
          <Container>
            <ContentWrap>
              <div>
                <Heading colour={brandColours.primary}>{heading}</Heading>
                <Text>{text}</Text>
              </div>
              <ButtonWrap>
                <Button to={`/${linkPage.slug}`}>{linkText}</Button>
              </ButtonWrap>
            </ContentWrap>
            <ImageWrap>
              <Image fluid={fluid} alt={alt} />
            </ImageWrap>
          </Container>
        </Background>
      </Container>
    </CallToActionStrip>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query CtaStripQuery {
        datoCmsCallToActionStrip {
          heading
          text
          linkText
          linkPage {
            ... on DatoCmsHome {
              slug
            }
            ... on DatoCmsPage {
              slug
            }
            ... on DatoCmsCompetitionsArchive {
              slug
            }
            ... on DatoCmsCompetitionSingle {
              slug
            }
            ... on DatoCmsEventsArchive {
              slug
            }
            ... on DatoCmsEventSingle {
              slug
            }
            ... on DatoCmsNewsArchive {
              slug
            }
            ... on DatoCmsNewsSingle {
              slug
            }
          }
          backgroundImage {
            url
            fluid(
              maxHeight: 400
              maxWidth: 1500
              imgixParams: {
                auto: "compress"
                fit: "crop"
                h: "400"
                w: "1500"
              }
            ) {
              ...GatsbyDatoCmsFluid
            }
            alt
          }
        }
      }
    `}
    render={data => <CallToActionComponent data={data} {...props} />}
  />
);
