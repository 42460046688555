import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const StyledResRetImg = styled(Img)`
  vertical-align: bottom;
  max-height: 0;
  max-width: 100%;
  ${({ fixed }) => {
    return `
      padding-bottom: ${100 / fixed.aspectRatio}%;
    `;
  }}
`;

export const ResRetImg = ({ image: { fixed, alt }, ...props }) => {
  return <StyledResRetImg fixed={fixed} alt={alt} {...props} />;
};
