export const standardColours = {
  black: '#000',
  white: '#FFF',
};

export const brandColours = {
  primary: '#DC3636',
  secondary: '#303030',
  tertiary: '#1B1B1B',
  quaternary: '#F2F2F2',
  quinary: '#F4F4F4',
  senary: '#F7F7F7',
  septenary: '#ACACAC',
  octonary: '#E0E0E0',
};
