import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import {
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
} from '../../utilities';

const commonButtonStyles = () => {
  return css`
    display: inline-block;
    padding: 18px;
    height: 100%;
    min-width: 160px;
    max-width: 200px;
    color: ${standardColours.white};
    font-weight: ${fontWeights.bold};
    text-align: center;
    background: ${brandColours.primary};
  `;
};

const StyledButton = styled.button`
  ${commonButtonStyles()};
  ${fontSize(16)};
  border: none;
  cursor: pointer;
`;

const StyledButtonLink = styled(Link)`
  ${commonButtonStyles()};
`;

export const Button = ({ children, ...props }) => {
  const ButtonComponent = props.to ? StyledButtonLink : StyledButton;

  return (
    <ButtonComponent {...props}>{children || 'Learn More'}</ButtonComponent>
  );
};
