import { css } from 'styled-components';
import { minBreakpointQuery } from '.';

export const sectionPaddings = () => {
  return css`
    padding: 40px 0;
    
    ${minBreakpointQuery.tiny`
      padding-top: 50px;
      padding-bottom: 50px;
    `}
    
    ${minBreakpointQuery.small`
      padding-top: 60px;
      padding-bottom: 60px;
    `}

    ${minBreakpointQuery.medium`
      padding-top: 70px;
      padding-bottom: 70px;
    `}

    ${minBreakpointQuery.large`
      padding-top: 80px;
      padding-bottom: 80px;
    `}

    ${minBreakpointQuery.xlarge`
      padding-top: 90px;
      padding-bottom: 90px;
    `}

    ${minBreakpointQuery.xxlarge`
      padding-top: 100px;
      padding-bottom: 100px;
    `}

    ${minBreakpointQuery.xxxlarge`
      padding-top: 110px;
      padding-bottom: 110px;
    `}

    ${minBreakpointQuery.xxxxlarge`
      padding-top: 120px;
      padding-bottom: 120px;
    `}
  `;
};
