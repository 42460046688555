import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1000px;

  &.large {
    max-width: 1540px;
  }
`;

export const ContainerComponent = ({ className, children }) => (
  <Container className={className}>{children}</Container>
);
