import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../../utilities';
import { Container } from '.';

const StyledGridBlock = styled.div`
  ${({ margins }) => {
    if (margins) {
      return `
        ${sectionMargins()};
      `;
    }
  }}
`;

const StyledGridBlockInner = styled.div`
  ${({ columns, gap }) => {
    if (columns && gap) {
      return minBreakpointQuery.smedium`
        display: grid;
        grid-template-columns: repeat(${columns}, 1fr);
        grid-gap: ${gap};
      `;
    }
  }}
`;

export const GridBlock = ({ columns, gap = '20px', margins, children }) => {
  return (
    <StyledGridBlock margins={margins}>
      <Container>
        <StyledGridBlockInner columns={columns} gap={gap}>
          {children}
        </StyledGridBlockInner>
      </Container>
    </StyledGridBlock>
  );
};

const StyledGridColumn = styled.div`
  ${({ span }) => {
    if (span) {
      return minBreakpointQuery.smedium`
        grid-column: span ${span};
      `;
    }
  }}
`;

export const GridColumn = ({ span, children }) => {
  return <StyledGridColumn span={span}>{children}</StyledGridColumn>;
};
