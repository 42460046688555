import React from 'react';
import styled from 'styled-components';
import { Container } from './ui';
import {
  standardColours,
  fluidFontSize,
  breakpointSizes,
  minBreakpointQuery,
} from '../utilities';

const Copyright = styled.div`
  padding: 30px 0;
  ${fluidFontSize('14px', '16px', breakpointSizes.tiny, breakpointSizes.large)}
  border-top: 1px solid ${standardColours.black};
  ${minBreakpointQuery.large`
    padding: 40px 0;
  `}
`;

const Wrapper = styled.div`
  text-align: center;
  ${minBreakpointQuery.mlarge`
    display: flex;
    justify-content: space-between;
  `}

  p {
    margin-top: 16px;
    ${minBreakpointQuery.mlarge`
      margin-top: 0;
    `}
    &:first-child {
      margin-top: 0;
    }
  }
`;

export default () => (
  <Copyright>
    <Container>
      <Wrapper>
        <p>&copy; {new Date().getFullYear()} Birkenhead Gymnastics Academy</p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.attachdigital.co.uk/"
          >
            Website by Attach Digital
          </a>
        </p>
      </Wrapper>
    </Container>
  </Copyright>
);
