import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  brandColours,
  fontWeights,
  fontSize,
  standardTransition,
} from '../../utilities';

export const StyledHTMLContent = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 12px 0;
    font-weight: ${fontWeights.bold};
    line-height: 1.5;

    ${minBreakpointQuery.small`
      margin-top: 14px;
      margin-bottom: 14px;
    `}

    ${minBreakpointQuery.medium`
      margin-top: 16px;
      margin-bottom: 16px;
    `}
  }

  h1 {
    ${fontSize(24)};

    ${minBreakpointQuery.small`
      ${fontSize(26)};
    `}
  }

  h2 {
    ${fontSize(22)};

    ${minBreakpointQuery.small`
      ${fontSize(24)};
    `}
  }

  h3 {
    ${fontSize(20)};

    ${minBreakpointQuery.small`
      ${fontSize(22)};
    `}
  }

  h4 {
    ${fontSize(19)};

    ${minBreakpointQuery.small`
      ${fontSize(20)};
    `}
  }

  h5 {
    ${fontSize(18)};

    ${minBreakpointQuery.small`
      ${fontSize(19)};
    `}
  }

  h6 {
    ${fontSize(17)};

    ${minBreakpointQuery.small`
      ${fontSize(18)};
    `}
  }

  p {
    margin-bottom: 20px;
    ${fontSize(14)};
    line-height: 1.7;

    ${minBreakpointQuery.small`
      margin-bottom: 24px;
      ${fontSize(15)};
    `}

    ${minBreakpointQuery.medium`
      margin-bottom: 28px;
      ${fontSize(16)};
    `}

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    position: relative;
    color: ${brandColours.primary};
    border-bottom: 1px solid transparent;
    ${standardTransition('border-color')};

    &:hover {
      border-bottom-color: ${brandColours.primary};
    }
  }

  ul,
  ol {
    margin-bottom: 20px;

    ${minBreakpointQuery.small`
      margin-bottom: 24px;
    `}

    ${minBreakpointQuery.medium`
      margin-bottom: 28px;
    `}

    li {
      margin-bottom: 10px;
      line-height: 1.7;

      ${minBreakpointQuery.small`
        margin-bottom: 12px;
      `}

      ${minBreakpointQuery.medium`
        margin-bottom: 14px;
      `}

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul {
    li {
      position: relative;
      padding-left: 20px;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 100%;
        width: 5px;
        background-color: ${brandColours.primary};
      }
    }
  }

  ol {
    list-style-type: decimal;

    li {
      margin-left: 16px;
    }
  }
`;

export const HTMLContent = ({ content }) => {
  return <StyledHTMLContent dangerouslySetInnerHTML={{ __html: content }} />;
};
